import { EventCombined, EventSeasonCombined } from '@gf/cross-platform-lib/interfaces';
import { useMemo } from 'react';

export const useDonationsRaised = (fundraiserItem: EventSeasonCombined | EventCombined) =>
  useMemo(() => {
    if (!fundraiserItem) {
      return { donationsRaised: [], donationsRaisedTotal: 0 };
    }
    const donationsRaised = fundraiserItem?.ticketTypes.map(
      ticketTypes =>
        fundraiserItem &&
        fundraiserItem.salesInfo &&
        Object.entries(fundraiserItem.salesInfo.productSalesMap).map(salesTickets => {
          if (salesTickets[0] === ticketTypes.id.toString()) {
            if (salesTickets[1].ticketsSold && salesTickets[1].ticketSales) {
              const ticketsSold = salesTickets[1].ticketsSold;
              const ticketFees = ticketTypes.fee;
              const totalFees = ticketsSold * ticketFees;
              const ticketSales = salesTickets[1].ticketSales - totalFees;
              return ticketSales;
            }
          }
        })
    );

    const donationsRaisedTotal = donationsRaised
      .flat()
      .filter((donationsRaised): donationsRaised is number => donationsRaised !== undefined)
      .reduce((acc, cur) => acc + cur, 0);
    return { donationsRaised, donationsRaisedTotal };
  }, [fundraiserItem]);
