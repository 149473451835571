import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './Skeleton.styles';
import { View } from 'react-native';

export const Skeleton = styled.View`
  ${styles.skeleton}
`;

export const Skeleton16 = styled.View`
  ${styles.skeleton}
  height: 16px;
`;

export const Skeleton20 = styled.View`
  ${styles.skeleton}
  height: 20px;
`;

export const Skeleton24 = styled.View`
  ${styles.skeleton}
  height: 24px;
`;

export const Skeleton32 = styled.View`
  ${styles.skeleton}
  height: 32px;
`;

export const SkeletonBorderRadius4 = styled.View`
  ${styles.skeleton}
  border-radius: 4px;
`;

export const SkeletonWrapper = styled.View`
  ${styles.skeletonWrapper}
`;

export const SkeletonRow = styled.View`
  ${styles.row}
`;

export const SkeletonColumn = styled.View`
  ${styles.column}
`;

const NextHelpWrapper = styled.View`
  ${styles.skeletonWrapper}
  align-items: center;
`;

const DesktopSkeletonContainer = styled.View`
  ${styles.desktopSkeletonContainer}
`;
const MobileSkeletonContainer = styled.View`
  ${styles.mobileSkeletonContainer}
`;

const MobileEventSkeletonView = styled.View`
  ${styles.mobileEventSkeletonView}
`;

const CategoryLoadingSkeletonContainer = styled.View`
  ${styles.categoriesLoadingSkeleton}
`;

const DefaultWrapper = styled.View`
  ${styles.defaultWrapper}
`;

export const renderSkeletonItem = ({ width, padding }: { width?: string; padding?: string }) => (
  <SkeletonRow padding={padding}>
    <Skeleton20 width='20px' margin='0 8px 0 0' />
    <Skeleton16 width={width} margin='2px 0 0 0' />
  </SkeletonRow>
);

export const renderNextHelpSkeleton = (isMobile: boolean) =>
  isMobile ? (
    <NextHelpWrapper height='188px' padding='16px'>
      <Skeleton24 width='180px' />
      <Skeleton16 width='307px' margin='4px 0 4px 0' />
      <Skeleton16 width='329px' />
      <Skeleton16 width='307px' margin='4px 0 24px 0' />
      <SkeletonBorderRadius4 height='48px' width='329px' />
    </NextHelpWrapper>
  ) : (
    <SkeletonWrapper height='116px' margin='48px 0 0 0'>
      <SkeletonRow padding='24px' isSpaceBetween>
        <SkeletonColumn>
          <Skeleton24 width='180px' />
          <Skeleton16 width='500px' margin='4px 0 4px 0' />
          <Skeleton16 width='70px' />
        </SkeletonColumn>
        <SkeletonBorderRadius4 height='52px' width='164px' />
      </SkeletonRow>
    </SkeletonWrapper>
  );

export const DesktopEventSkeleton = () => (
  <DesktopSkeletonContainer>
    <View style={{ display: 'flex', flexDirection: 'row', width: '80%', alignItems: 'center' }}>
      <Skeleton16 width='18px' height='18px' />
      <Skeleton16 width='60px' height='18px' margin='0 0 0 8px' />
      <Skeleton32 width='32px' margin='0 0 0 30px' />
      <SkeletonColumn margin='0 0 0 30px'>
        <Skeleton32 width='60px' />
        <Skeleton16 width='48px' height='18px' margin='4px 0 4px 4px' />
        <Skeleton16 width='60px' height='18px' />
      </SkeletonColumn>
      <SkeletonColumn margin='0 0 0 24px'>
        <Skeleton24 width='296px' />
        <Skeleton16 width='264px' margin='4px 0 4px 0' />
        <Skeleton16 width='156px' />
      </SkeletonColumn>
    </View>
    <SkeletonColumn>
      <Skeleton32 width='136px' borderRadius='4px' />
    </SkeletonColumn>
  </DesktopSkeletonContainer>
);

export const DesktopPassSkeleton = () => (
  <DesktopSkeletonContainer>
    <View style={{ display: 'flex', flexDirection: 'row', width: '80%', alignItems: 'center' }}>
      <Skeleton16 width='18px' height='18px' />
      <Skeleton16 width='60px' height='18px' margin='0 0 0 8px' />
      <Skeleton32 width='32px' margin='0 0 0 30px' />
      <SkeletonColumn margin='0 0 0 24px'>
        <Skeleton24 width='296px' />
        <Skeleton16 width='264px' margin='4px 0 4px 0' />
        <Skeleton16 width='156px' />
      </SkeletonColumn>
    </View>
    <SkeletonColumn>
      <Skeleton32 width='136px' borderRadius='4px' />
    </SkeletonColumn>
  </DesktopSkeletonContainer>
);

export const MobileEventSkeleton = () => (
  <MobileSkeletonContainer>
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: '30%'
      }}
    >
      <Skeleton32 width='32px' margin='0 0 0 12px' />
      <Skeleton32 width='60px' margin='4px 0 0 0' />
      <Skeleton16 width='48px' height='18px' margin='4px 0 0 4px' />
      <Skeleton16 width='60px' height='18px' margin='4px 0 0 0' />
      <SkeletonRow>
        <Skeleton16 width='18px' height='18px' margin='15px 12px 0  0' />
        <Skeleton16 width='34px' height='18px' margin='15px 0 0  0' />
      </SkeletonRow>
    </View>
    <MobileEventSkeletonView>
      <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
        <Skeleton16 width='18px' height='18px' margin='15px 12px 0  0' />
        <Skeleton16 width='60px' height='18px' margin='15px 0 0  0' />
      </View>
      <Skeleton16 width='100%' height='18px' margin='8px 0 0 0' />
      <Skeleton16 width='214px' height='18px' margin='4px 0 4px 0' />
      <Skeleton16 width='106px' height='18px' />
      <View style={{ alignItems: 'flex-end' }}>
        <Skeleton32 width='136px' borderRadius='4px' margin='37.5px 0 0 0' />
      </View>
    </MobileEventSkeletonView>
  </MobileSkeletonContainer>
);

export const EventSkeleton = ({ isMobile = false, key = Date.now() }) =>
  isMobile ? <MobileEventSkeleton key={key} /> : <DesktopEventSkeleton key={key} />;

export const PassSkeleton = ({ isMobile = false, key = Date.now() }) =>
  isMobile ? <MobileEventSkeleton key={key} /> : <DesktopPassSkeleton key={key} />;

export const CategoriesLoadingSkeleton = ({ isMobile = false }) => (
  <CategoryLoadingSkeletonContainer isMobile={isMobile}>
    <Skeleton20 width={'148px'} />
    {isMobile ? (
      <View style={{ marginTop: 8, marginBottom: 8, flexDirection: 'row' }}>
        {Array.from({ length: 4 }).map((_, index) => (
          <Skeleton20 key={index} width={'72px'} height={'24px'} borderRadius={'4px'} margin={'0 16px 0 0'} />
        ))}
      </View>
    ) : (
      <View style={{ marginTop: 8, marginBottom: 8, flexDirection: 'row' }}>
        {Array.from({ length: 10 }).map((_, index) => (
          <Skeleton20 key={index} width={'72px'} height={'24px'} borderRadius={'4px'} margin={'0 16px 0 0'} />
        ))}
      </View>
    )}

    <Skeleton20 width={'148px'} />
    <View style={{ marginTop: 8, marginBottom: 8, flexDirection: 'row' }}>
      {Array.from({ length: 2 }).map((_, index) => (
        <Skeleton20 key={index} width={'72px'} height={'24px'} borderRadius={'4px'} margin={'0 16px 0 0'} />
      ))}
    </View>
  </CategoryLoadingSkeletonContainer>
);

export const EventListLoadingSkeleton = ({ isMobile = false }) => (
  <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
    <Skeleton20 width={'180px'} margin='16px 0 16px 0' />
    {Array.from({ length: 2 }).map((_, index) =>
      index % 2 === 0 ? (
        <EventSkeleton isMobile={isMobile} key={index} />
      ) : (
        <PassSkeleton isMobile={isMobile} key={index} />
      )
    )}
    <Skeleton20 width={'180px'} margin='16px 0 16px 0' />
    {Array.from({ length: 2 }).map((_, index) =>
      index % 2 === 0 ? (
        <EventSkeleton isMobile={isMobile} key={index} />
      ) : (
        <PassSkeleton isMobile={isMobile} key={index} />
      )
    )}
  </View>
);

export const FooterDesktopSkeleton = () => (
  <SkeletonRow padding='0 24px' isSpaceBetween>
    <SkeletonColumn>
      <SkeletonRow>
        <SkeletonColumn padding='0 32px 0 0'>
          <Skeleton20 width='60px' />
          <Skeleton16 width='70px' margin='4px 0 4px 0' />
          <Skeleton16 width='60px' />
          <Skeleton16 width='70px' margin='4px 0 0 0' />
        </SkeletonColumn>
        <SkeletonColumn padding='0 32px 0 0'>
          <Skeleton20 width='60px' />
          <Skeleton16 width='70px' margin='4px 0 4px 0' />
          <Skeleton16 width='60px' />
        </SkeletonColumn>
        <SkeletonColumn padding='0 32px 0 0'>
          <Skeleton20 width='60px' />
          <Skeleton16 width='70px' margin='4px 0 4px 0' />
          <Skeleton16 width='60px' />
        </SkeletonColumn>
      </SkeletonRow>
      <SkeletonRow padding='80px 0 32px 0'>
        <Skeleton20 width='60px' />
        <Skeleton16 width='70px' margin='0 0 0 32px' />
      </SkeletonRow>
    </SkeletonColumn>
    <SkeletonColumn alignItems='flex-end'>
      <Skeleton20 width='160px' />
      <SkeletonRow padding='16px 0 0 0'>
        <SkeletonBorderRadius4 height='48px' width='120px' />
        <SkeletonBorderRadius4 height='48px' width='120px' margin='0 0 0 24px' />
      </SkeletonRow>
      <Skeleton16 width='120px' margin='4px 0 4px 0' />
      <Skeleton16 width='70px' />
      <SkeletonRow padding='80px 0 32px 0'>
        <Skeleton16 width='300px' />
      </SkeletonRow>
    </SkeletonColumn>
  </SkeletonRow>
);

export const DefaultSkeletonComponent = ({ isMobile = false }) => (
  <DefaultWrapper>
    <SkeletonColumn width='100%' alignItems='center'>
      <Skeleton height='48' width='100%' borderRadius='0' />
      <SkeletonBorderRadius4 height='48px' width='200px' margin='80px 0 0 0' />
    </SkeletonColumn>
    {isMobile ? <Skeleton height='62' width='100%' borderRadius='0' /> : <FooterDesktopSkeleton />}
  </DefaultWrapper>
);
