import { css } from 'styled-components';

export const styles = {
  skeleton: css<{ width: string; height: string; borderRadius: string; margin: string }>`
    background-color: #d9d9d9;
    width: ${({ width }) => (width ? width : '100%')};
    height: ${({ height }) => (height ? height : '0')};
    border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '16px')};
    margin: ${({ margin }) => (margin ? margin : '0')};
  `,
  skeletonWrapper: css<{ width: string; height: string; borderRadius: string; padding: string; margin: string }>`
    border: 1px solid #dbdbdb;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    width: ${({ width }) => (width ? width : '100%')};
    height: ${({ height }) => (height ? height : '0')};
    border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '4px')};
    padding: ${({ padding }) => (padding ? padding : '0')};
    margin: ${({ margin }) => (margin ? margin : '0')};
  `,
  row: css<{ padding: string; margin: string; isSpaceBetween: boolean; alignItems: string }>`
    display: flex;
    flex-direction: row;
    align-items: ${({ alignItems }) => (alignItems ? alignItems : 'center')};
    width: auto;
    padding: ${({ padding }) => (padding ? padding : '0')};
    margin: ${({ margin }) => (margin ? margin : '0')};
    ${({ isSpaceBetween }) => (isSpaceBetween ? 'justify-content: space-between' : '')};
  `,
  column: css<{ width: string; padding: string; margin: string; alignItems: string }>`
    display: flex;
    flex-direction: column;
    padding: ${({ padding }) => (padding ? padding : '0')};
    width: ${({ width }) => (width ? width : 'auto')};
    margin: ${({ margin }) => (margin ? margin : '0')};
    align-items: ${({ alignItems }) => (alignItems ? alignItems : 'flex-start')};
  `,
  mobileSkeletonContainer: css`
    display: flex;
    flex-direction: row;

    width: 352px;
    padding: 16px;
    align-items: center;
    gap: 16px;

    border-radius: 4px;
    border: 1px solid #dbdbdb;

    background: #fff;
  `,
  desktopSkeletonContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 960px;
    padding: 16px;
    align-items: center;
    gap: 16px;

    border: 1px solid #dbdbdb;

    background: #fff;
  `,
  mobileEventSkeletonView: css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 70%;
    height: 100%;
    padding-right: 16px;
  `,
  categoriesLoadingSkeleton: css<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 12px;
    align-items: flex-start;
    width: ${({ isMobile }) => (isMobile ? '100%' : '960px')};
  `,
  defaultWrapper: css`
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    height: 100%;
  `
};
