import React, { createContext, useContext } from 'react';
import { RefreshControl, ScrollView } from 'react-native';

interface PullDownContextType {
  refreshing: boolean;
  setRefreshing: (refreshing: boolean) => void;
}

interface PullDownProviderProps {
  children: React.ReactNode;
  scrollRef?: React.RefObject<ScrollView>;
}

const PullDownContext = createContext<PullDownContextType>({
  refreshing: false,
  setRefreshing: _refreshing => {}
});

const PullRefreshProvider = ({ children, scrollRef }: PullDownProviderProps) => {
  const [refreshing, setRefreshing] = React.useState(false);
  const onRefresh = () => {
    setRefreshing(true);
  };
  return (
    <PullDownContext.Provider value={{ refreshing, setRefreshing }}>
      <ScrollView
        ref={scrollRef}
        refreshControl={
          <RefreshControl
            refreshing={false}
            onRefresh={onRefresh}
            style={{ backgroundColor: 'transparent' }}
            tintColor={'transparent'}
            colors={['transparent']}
          />
        }
        style={{
          flex: 1
        }}
      >
        {children}
      </ScrollView>
    </PullDownContext.Provider>
  );
};

const usePullRefresh = (refetch?: () => void, loading?: boolean) => {
  const context = useContext(PullDownContext);
  const { refreshing, setRefreshing } = context;
  React.useEffect(() => {
    if (refreshing === true && refetch) {
      refetch();
    }
  }, [refreshing]);
  React.useEffect(() => {
    if (refreshing && loading === false) {
      setRefreshing(false);
    }
  }, [loading]);
  return context;
};

export { usePullRefresh, PullRefreshProvider };
